import React from 'react'
import mainLogo from '../assets/img/mainLogo.svg'
import { Link, useNavigate } from 'react-router-dom'

export default function Navbar() {

    const navigate = useNavigate();

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue) {
            navigate(selectedValue);
        }
    };

    return (
        <div className='w-screen fixed top-0 left-0 z-20'>
            <div className='min-[1920px]:w-[1920px] w-full mx-auto'>
                <div className='min-[1240px]:w-[1240px] w-full md:h-[180px] h-[140px] md:px-0 px-5 md:container md:mx-auto flex justify-between items-center'>
                    <img src={mainLogo} alt="" className='md:h-[150] h-[90px] md:w-[110]' />
                    <div className='h-[50px] lg:text-lg md:px-10 md:py-10 flex items-center xl:space-x-10 space-x-5 md:bg-white font-medium rounded-2xl sm:shadow-2xl'>
                        <div className='md:flex hidden xl:space-x-10'>
                            <p className='hover:bg-[#89C640] py-1 px-3 rounded hover:text-white'>For businesses</p>
                            <p className='hover:bg-[#89C640] py-1 px-3 rounded hover:text-white'>How it works</p>
                            <p className='hover:bg-[#89C640] py-1 px-3 rounded hover:text-white'>Our program</p>
                            <p className='hover:bg-[#89C640] py-1 px-3 rounded hover:text-white'>
                                <Link to={'/AboutUs'}>About us</Link></p>
                            <p className='hover:bg-[#89C640] py-1 px-3 rounded hover:text-white'>Blog</p>
                        </div>
                        <button className='bg-[#89C640] py-1 px-3 rounded text-white'>Start free trial</button>
                        <select
                            name=""
                            id=""
                            className='md:hidden w-[150px] focus:outline-none py-3 rounded shadow-2xl bg-[#F3DAC8]'
                            onChange={handleSelectChange}
                        >
                            <option value="">Menu</option>
                            <option value="/">Home page</option>
                            <option value="/AboutUs">About us</option>
                            <option value="/News">News and resources</option>
                            <option value="/ContactUs">Contact us</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}
