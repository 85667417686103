import React from 'react'
import Navbar2 from '../shared/Navbar2'
import Footer from '../shared/Footer'
import contactUs1 from '../assets/img/contactUs1.svg'
import home3 from '../assets/img/home3.svg'

export default function ContactUs() {
    return (
        <>
            <div className='w-screen'>
                <Navbar2 />

                <div className='min-[1920px]:w-[1920px] w-full mx-auto'>

                    <div className='w-full md:h-[821px] h-[621px] flex justify-center items-center' style={{ backgroundImage: `URL('${contactUs1}')` }}>

                        <div className='mt-20 min-[1240px]:w-[1240px] w-full md:mx-auto md:container md:px-0 px-5 md:flex md:space-x-5'>
                            <div className='w-full'>
                                <p className='md:text-[60px] text-[30px] font-extrabold'>Contact Us</p>
                                <p className='sm:text-2xl'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla </p>
                            </div>
                            <div className='mt-10 w-full'>
                                <input type="text" placeholder='Enter your name' className='w-full focus:outline-none py-3 px-5 rounded' />
                                <textarea placeholder='Enter your message' rows={6} className='mt-5 w-full focus:outline-none py-3 px-5 rounded'></textarea>
                            </div>
                        </div>

                    </div>

                    <div className='min-[1240px]:w-[1240px] mx-auto md:container md:px-0 px-5 sm:mt-40 mt-20'>
                        <div className='w-full flex'>
                            <div className='w-full md:flex justify-center hidden'>
                                <img src={home3} alt="" className='' />
                            </div>
                            <div className='md:mt-0 mt-5 col-span-4 space-y-5 md:px-10 flex flex-col justify-center'>
                                {/* title */}
                                <div className=''>
                                    <div className='flex flex-col'>
                                        <p className='2xl:text-[50px] md:text-[30px] sm:text-[25px] text-2xl font-extrabold'>
                                        Partner with Ora
                                        </p> 
                                        <div className='mt-2 flex items-center'>
                                            <div className='w-10 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                                            <div className='ms-2 w-4 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                                        </div>
                                    </div>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac.</p>
                                <button className='w-1/2 bg-brown py-3 px-10 md:text-2xl text-white font-bold rounded-xl'>Submit</button>
                            </div>
                        </div>
                    </div>


                </div>

                <Footer />
            </div>
        </>
    )
}
