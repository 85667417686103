import React from 'react'
import Navbar from '../shared/Navbar'
import Footer from '../shared/Footer'
import home1 from '../assets/img/home1.jpg'
import home2 from '../assets/img/home2.jpg'
import home3 from '../assets/img/home3.svg'
import persone1 from '../assets/img/person1.jpg'
import persone2 from '../assets/img/persone2.jpg'
import persone3 from '../assets/img/persone3.jpg'
import persone4 from '../assets/img/persone4.jpg'
import greenRightNavigation from '../assets/img/greenRightNavigation.svg'
import bgImg1 from '../assets/img/bgImg1.svg'
import bgImg2 from '../assets/img/bgImg2.svg'
import bgImg3 from '../assets/img/bgImg3.svg'
import appleLogo from '../assets/img/appleLogo.svg'
import gymLogo from '../assets/img/gymLogo.svg'
import wellBeingLogo from '../assets/img/wellBeingLogo.svg'
import profileimg1 from '../assets/img/profileimg1.jpg'
import profileimg2 from '../assets/img/profileImag2.jpg'
import profileimg3 from '../assets/img/profileImag3.jpg'
import profileimg4 from '../assets/img/profileImag4.jpg'
import playStoreIcon from '../assets/img/playStoreIcon.svg'
import appleIcon from '../assets/img/appleIcon.svg'
import vegetableSalad from '../assets/img/vegetableSalad.jpg'

export default function Home() {

  const person = [
    {
      name: 'INDIVIDUALS',
      img: persone1
    },
    {
      name: 'HEALTH PLANS',
      img: persone2
    },
    {
      name: 'EMPLOYERS',
      img: persone3
    },
    {
      name: 'PARTNERS',
      img: persone4
    },

  ]

  return (
    <>
      <div className='w-screen'>
        <Navbar />

        <div className='min-[1920px]:w-[1920px] w-full mx-auto'>

          <div className='bg-[#F3DAC8] xl:h-[870px] lg:h[700px] md:h-[570px] h-[450px] w-screen absolute -z-10 top-0 left-0'></div>

          <div className='xl:h-[1000px] lg:h-[750px] md:h-[630px] h-[450px] w-full md:px-0 px-5 flex md:items-center items-end'>

            <div className='w-full  mt-20 md:mb-0 mb-10'>
              <div className='xl:w-[580px] lg:w-[469px] md:w-[360px] w-full md:ms-auto lg:me-9 md:me-5 space-y-5'>
                <h1 className="lg:text-[50px] sm:text-[30px] text-[25px] font-extrabold text-gray-800">Say yes to your health and no to chronic disease</h1>
                <p className="sm:text-2xl text-gray-500">Yes Health has always been about meeting you exactly where you are.</p>
                <button className="bg-brown text-white px-6 py-3 rounded-2xl">Start free trial</button>
              </div>
            </div>

            <div className='w-full md:mt-auto md:flex hidden'>
              <img src={home1} alt="Healthy lifestyle" className="md:mt-auto xl:h-[820px] lg:h-[620px] md:h-[420px] h-[300px] w-full rounded-tr-[37%] rounded-bl-[37%]" />
            </div>

          </div>

          {/* Discover Yes Health for */}
          <div className='min-[1240px]:w-[1240px] mx-auto md:container md:px-0 px-5 mt-20 '>

            {/* title */}
            <div className='flex justify-center'>
              <div className='flex flex-col'>
                <p className='title'>
                  <span className='text-brown'>Discover</span> Yes Health for
                </p>
                <div className='flex items-center'>
                  <div className='w-10 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                  <div className='ms-2 w-4 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                </div>
              </div>
            </div>

            <img src={bgImg1} alt="background design" className='absolute left-0 h-auto hidden md:block' />

            <div className='mt-10  grid lg:grid-cols-4 sm:grid-cols-2 gap-5 w-full'>
              {
                person.map((item, index) => {
                  return <div className='w-full flex flex-col ' key={index}>
                    <div className='xl:h-[207px] h-[170px] w-full flex justify-center bg-cover rounded-3xl' style={{ backgroundImage: `url(${item.img})`, backgroundRepeat: 'no-repeat' }}>
                      <p className=' mb-4 w-full text-center mx-8 rounded-full mt-auto bg-brown text-white font-bold'>{item.name}</p>
                    </div>
                    <div className='mt-2 flex'>
                      <img src={greenRightNavigation} alt="" className='me-2' />
                      <p className='text-green-500'>learn more</p>
                    </div>
                  </div>
                })
              }
            </div>

          </div>

          {/* a personal coach in your pocket */}
          <div className='min-[1240px]:w-[1240px] mx-auto md:container md:px-0 px-5 mt-20 '>

            {/* title */}
            <div className='flex justify-center'>
              <div className='flex flex-col'>
                <p className='title'>
                  <span className='text-brown'>Personal coach</span> in your pocket
                </p>
                <div className='flex items-center'>
                  <div className='w-10 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                  <div className='ms-2 w-4 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                </div>
              </div>
            </div>
            <p className='text-center text-2xl mt-7 lg:px-56 sm:px-10'>
              Our all-mobile program helps you with advice when and where you need it, to help you eat healthy meals, grocery shop, read food labels, exercise more and stress less
            </p>

          </div>

          <div className='mt-20 w-full md:px-0 px-5 flex'>
            <div className='w-full md:flex hidden'>
              <img src={home2} alt="Healthy lifestyle" className="xl:h-[750px] lg:h-[620px] md:h-[420px] h-[300px] w-full object-cover rounded-br-[30%]" />
            </div>
            <div className='w-full sm:ps-5'>
              <div className='xl:w-[590px] lg:w-[480px] md:w-[390px] w-full flex flex-col items-center sm:space-y-10 space-y-5'>

                <div className='flex p-0'>
                  <img src={appleLogo} alt="" className='z-10 sm:w-20 w-16' />
                  <div className='bg-sky sm:px-14 sm:ps-14 ps-10 sm:pe-14 pe-3 sm:py-12 py-5 rounded-2xl relative right-10 w-full'>
                    <p className='text-2xl font-bold'>NUTRITION</p>
                    <p className='text-para mt-3'>Snap photos of the meals you love and get instant tips on how to make them healthier. We keep our comments upbeat. No judgment here.</p>
                  </div>
                </div>
                <div className='flex p-0'>
                  <img src={gymLogo} alt="" className='z-10 sm:w-20 w-16' />
                  <div className='bg-sky sm:px-14 sm:ps-14 ps-10 sm:pe-14 pe-3 sm:py-12 py-5 rounded-2xl relative right-10 w-full'>
                    <p className='text-2xl font-bold'>FITNESS</p>
                    <p className='text-para mt-3'>Get your body moving — whether it's dancing or a brisk morning walk — with your coach by your side, cheering you on.</p>
                  </div>
                </div>
                <div className='flex p-0'>
                  <img src={wellBeingLogo} alt="" className='z-10 sm:w-20 w-16' />
                  <div className='bg-sky sm:px-14 sm:ps-14 ps-10 sm:pe-14 pe-3 sm:py-12 py-5 rounded-2xl relative right-10 w-full'>
                    <p className='text-2xl font-bold'>WELL-BEING</p>
                    <p className='text-para mt-3'>Say yes to more deep breaths, more nature — more love. We understand the strong connection between happiness and health and promise to remind you — often.</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

          {/* Why Yes Health Works */}
          <div className='min-[1240px]:w-[1240px] mx-auto md:container md:px-0 px-5 mt-20 '>

            {/* title */}
            <div className='flex justify-center'>
              <div className='flex flex-col'>
                <p className='title'>
                  Why Yes Health Works
                </p>
                <div className='flex items-center'>
                  <div className='w-10 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                  <div className='ms-2 w-4 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                </div>
              </div>
            </div>

            <img src={bgImg2} alt="background design" className='absolute right-0 h-auto hidden md:block' />
            <p className='text-center text-2xl mt-7 lg:px-56 sm:px-10'>
              Our revolutionary programs use proven science from NIH, CDC and UCSF. It works because it’s personalized for you, encouraging small changes over time that will have you eating better, being more active—and enjoying your new healthy life
            </p>

            <div className='md:mt-36 mt-20 grid md:grid-cols-3 sm:grid-cols-2 lg:gap-20 gap-10 '>

              <div className='qwe'>
                <div className='bg-sky lg:py-20 py-14 lg:px-14 px-5 rounded-3xl'>
                  <p className='text-center text-para'>Taking a selfie and telling my coach how I feel
                    is like having a fitness trainer walk right next to me.</p>
                </div>
                <div className='mt-5 flex justify-center space-x-5'>
                  <img src={profileimg1} alt="" className='lg:h-18 h-14 rounded-full' />
                  <div className=''>
                    <p className='lg:text-[28px]'>Elizabeth Bell</p>
                    <p className='text-para'>Patient</p>
                  </div>
                </div>
              </div>
              <div className='qwe md:relative bottom-24'>
                <div className='bg-sky lg:py-20 py-14 lg:px-14 px-5 rounded-3xl'>
                  <p className='text-center text-para'>Knowing I was reporting my eating habits to a team of coaches helped me cut out a significant chunk of junk food.</p>
                </div>
                <div className='mt-5 flex justify-center space-x-5'>
                  <img src={profileimg2} alt="" className='lg:h-18 h-14 rounded-full' />
                  <div className=''>
                    <p className='lg:text-[28px]'>Steve Pen</p>
                    <p className='text-para'>Practic</p>
                  </div>
                </div>
              </div>
              <div className='qwe'>
                <div className='bg-sky lg:py-20 py-14 sm:px-10 px-5 rounded-3xl'>
                  <p className='text-center text-para'>After 16 weeks, I was 10 pounds lighter, two inches slimmer and craving fruits and veggies more than I ever thought possible.</p>
                </div>
                <div className='mt-5 flex justify-center space-x-5'>
                  <img src={profileimg3} alt="" className='lg:h-18 h-14 rounded-full' />
                  <div className=''>
                    <p className='lg:text-[28px]'>Justin Lee</p>
                    <p className='text-para'>Payer</p>
                  </div>
                </div>
              </div>


            </div>

          </div>
          
          {/* Let’s do this! */}
          <div className='min-[1240px]:w-[1240px] mx-auto md:container md:px-0 px-5 sm:mt-40 mt-20'>
            <div className='w-full flex'>
              <div className='w-full md:flex justify-center hidden'>
                <img src={home3} alt="" className='' />
              </div>
              <div className='w-full  sm:px-10'>
                <div className='w-full flex justify-between overflow-x-auto'>
                  <p className='bg-[#89C640] py-2 sm:px-5 px-2 text-white rounded-full'>individuals</p>
                  <p className='py-2 sm:px-5 px-2 text-nowrap'>Health plans</p>
                  <p className='py-2 sm:px-5 px-2'>Employers</p>
                  <p className='py-2 sm:px-5 px-2'>Partners</p>
                </div>
                <div className=''>
                  <p className='mt-5 md:text-[60px] text-[30px] font-extrabold'>Let’s do this!</p>
                  <div className='mt-5'>
                    <div className='sm:flex sm:space-x-5'>
                      <input type="text" placeholder='Enter your email' className='px-3 w-full py-3 bg-sky rounded-md' />
                      <input type="text" placeholder='Enter your name' className='sm:mt-0 mt-5 px-3 w-full py-3 bg-sky rounded-md' />
                    </div>
                    <textarea name="" id="" rows={5} placeholder='Message' className='px-3 w-full py-3 bg-sky rounded-xl mt-5'></textarea>
                    <button className='mt-5 bg-brown px-16 py-2 rounded-md text-white font-bold'>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* YOUR MEAL SCORE */}
          <div className='min-[1240px]:w-[1240px] h-full mx-auto md:container md:px-0 px-5 sm:mt-40 mt-20'>

            <div className='w-full'>
              <div className='bg-contain flex items-center justify-evenly' >
                <img src={bgImg3} className='absolute' alt="" />

                <div className='md:flex hidden bg-[#89C640] z-10 py-2 px-5 rounded-lg'>
                  <img src={playStoreIcon} alt="" />
                  <div className='ms-5 text-white text-center'>
                    <p className='text-sm'>Get it on</p>
                    <p className='font-bold'>Google play</p>
                  </div>
                </div>

                <div className='p-2 bg-[#F2F2F3] w-[382px] rounded-3xl z-10'>
                  <div className='bg-[#6BA744] h-full rounded-3xl'>

                    <div className='px-20 w-full flex justify-center'>
                      <div className='py-4 px-5 w-full bg-[#F2F2F3] rounded-b-xl'>
                        <hr className='border-4 rounded-full border-[#6BA744]' />
                      </div>
                    </div>

                    <div className='py-4'>
                      <p className='text-[23px] font-bold text-white text-center'>Your Meal Score</p>
                    </div>

                    <div className=''>
                      <img src={vegetableSalad} alt="" className='object-cover rounded-t-3xl' />
                      <div className='bg-white rounded-b-3xl'>
                        <div className='flex px-5 py-10 space-x-5'>
                          <img src={profileimg4} alt="" className='h-12 w-12 rounded-full' />
                          <div className=''>
                            <p className=''><span className='font-bold sm:text-xl'>Coach Kelly</span>, RDN</p>
                            <p className='mt-2 text-para sm:text-base text-xs'>Nicely balanced bowl!! If you find yourself getting hungry again later, try celery and peanut butter for a great high-energy option. Find more snack ideas in your Library.</p>
                            <p className='mt-4 text-para text-end text-sm'>2 mins ago</p>
                          </div>
                        </div>
                        <div className='w-full py-5 px-7'>
                          <button className='py-2 bg-brown text-white text-2xl font-bold w-full rounded-full'>REPLY</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className='relative bottom-20 md:flex hidden bg-[#89C640] z-10 py-2 px-5 rounded-lg'>
                  <img src={appleIcon} alt="" />
                  <div className='ms-5 text-white text-center'>
                    <p className='text-sm'>Available on</p>
                    <p className='font-bold'>AppStore</p>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
        <Footer mode='light' />
      </div>
    </>
  )
}
