import React from 'react'
import mainLogo from '../assets/img/mainLogo.svg'
import { Link } from 'react-router-dom'

export default function Footer({ mode }) {
    return (
        <footer className={`min-[1920px]:w-[1920px] w-full h-[300px] mx-auto  mt-20 ${mode === 'light' ? 'bg-[#F1F5F7]' : 'bg-[#F3DAC8]'}`}>

            <div className='min-[1240px]:w-[1240px] w-full h-full md:container md:mx-auto md:px-0 px-5 flex justify-evenly items-center text-[#6C6C6C] font-medium'>
                <div className=''>
                    <img src={mainLogo} alt="" className='h-[137px] w-[174px]' />
                </div>
                <div className='space-y-5'>
                    <p><Link to={'/'}>Home Page</Link></p>
                    <p><Link to={'/AboutUs'}>About us</Link></p>
                    <p><Link to={'/News'}>News and resousrce</Link> </p>
                </div>
                <div className='space-y-5'>
                    <p><Link to={'/ContactUs'}>Contact us</Link></p>
                    <p><Link to={'/PrivacyPolicy'}>Privacy policy </Link></p>
                    <p><Link to={'/TermsConditions'}>Terms & Conditions</Link></p>
                </div>
            </div>
            {
                mode === 'light' ?
                    <>
                        <div className='flex justify-center py-2'>
                            <p className='text-lg text-[#BDBDBD]'>© All rights reserved Yes Health 2021</p>
                        </div>
                    </> :
                    <>
                        <div className='flex justify-center py-2 bg-[#815338] w-full'>
                            <p className='text-lg text-white'>© Copyright 2021</p>
                        </div>
                    </>
            }

        </footer>
    )
}
