import React from 'react'
import Navbar2 from '../shared/Navbar2'
import Footer from '../shared/Footer'
import news1 from '../assets/img/news1.jpg'
import playButton from '../assets/img/playButton.svg'
import home3 from '../assets/img/home3.svg'

export default function News() {
    return (
        <>
            <div className='w-screen'>
                <Navbar2 />

                <div className='container-1920'>

                    {/* NEWS AND RESOURCES */}
                    <div className='md:mt-48 mt-20 w-full md:px-0 px-5 flex'>

                        <div className='w-full sm:ps-5'>
                            <div className='xl:w-[590px] lg:w-[480px] md:w-[390px] w-full 2xl:h-[700px] xl:h-[500px] lg:h-[400px] md:h-[320px] h-[300px] flex flex-col items-center justify-center md:ms-auto  sm:space-y-10 space-y-5'>
                                <h1 className='md:text-[60px] text-[30px] font-extrabold'>News and resources</h1>
                                <p className='sm:text-2xl'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis ac fringilla vel, iaculis</p>
                            </div>
                        </div>

                        <div className='w-full md:flex hidden 2xl:h-[700px] xl:h-[500px] lg:h-[400px] md:h-[320px] h-[300px] p-10 lg:bg-contain bg-cover rounded-bl-[30%]' style={{ backgroundImage: `URL('${news1}')`, backgroundRepeat: 'no-repeat' }}>
                            <div className='lg:w-1/2 p-5 flex flex-col h-fit bg-[#FFFAF6] rounded-xl space-y-5'>
                                <p>Ora for Practices</p>
                                <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac. </p>
                                <p className='text-end font-bold'>Read more &#x2192;</p>
                            </div>
                        </div>
                    </div>

                    {/* LATEST NEWS */}
                    <div className='container-1240'>

                        <div className=''>
                            <h2 className='md:text-[60px] text-[30px] font-extrabold'>Latest news</h2>

                            <div className='grid lg:grid-cols-3 sm:grid-cols-2 gap-5'>
                                {Array.from({ length: 6 }).map((item, index) => {
                                    return (
                                        <div className='p-5 flex flex-col h-fit rounded-xl space-y-5' key={index}>
                                            <div className='w-full h-[300px] bg-[#F2F2F2] rounded-2xl flex'>
                                                <span className='mt-auto p-5 text-2xl'>&#x2192;</span>
                                            </div>
                                            <p>Ora for Practices</p>
                                            <p className='md:pe-20'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac. </p>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='mt-10 w-full flex justify-center'>
                                <button className='btn-primary'>Load more</button>
                            </div>

                        </div>

                    </div>

                    {/* ORA ACADEMY */}
                    <div className='w-full bg-[#F2F2F2] py-20 my-20'>
                        <div className='container-1240'>

                            <div className='md:flex'>
                                <div className='sm:w-3/4 w-full sm:space-y-10 space-y-10'>
                                    <h2 className='title'>Ora Academy</h2>
                                    <p className='sm:text-2xl sm:pe-20'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac. Augue risus est tortor, amet netus faucibus blandit feugiat in.</p>
                                    <button className='btn-primary'>Learn more</button>
                                </div>
                                <div className='mt-10 md:mt-0 w-full sm:h-[500px] h-[300px] border flex justify-center items-center rounded-3xl bg-[#C4C4C4]'>
                                    <img src={playButton} alt="" />
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* PARTERNER WITH ORA */}
                    <div className='min-[1240px]:w-[1240px] mx-auto md:container md:px-0 px-5 sm:mt-40 mt-20'>
                        <div className='w-full flex'>
                            <div className='w-full md:flex justify-center hidden'>
                                <img src={home3} alt="" className='' />
                            </div>
                            <div className='md:mt-0 mt-5 col-span-4 space-y-5 md:px-10 flex flex-col justify-center'>
                                {/* title */}
                                <div className=''>
                                    <div className='flex flex-col'>
                                        <p className='2xl:text-[50px] md:text-[30px] sm:text-[25px] text-2xl font-extrabold'>
                                            Partner with Ora
                                        </p>
                                        <div className='mt-3 flex items-center'>
                                            <div className='w-10 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                                            <div className='ms-2 w-4 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                                        </div>
                                    </div>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac.</p>
                                <button className='w-1/2 bg-brown py-3 px-10 md:text-2xl text-white font-bold rounded-xl'>Submit</button>
                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
            </div>
        </>
    )
}
