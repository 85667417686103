import React from 'react'
import Navbar2 from '../shared/Navbar2'
import Footer from '../shared/Footer'
import aboutUs1 from '../assets/img/aboutUs1.jpg'
import aboutUs2 from '../assets/img/aboutUs2.jpg'
import aboutUs3 from '../assets/img/aboutUs3.svg'
import profileimg1 from '../assets/img/profileimg1.jpg'
import profileimg2 from '../assets/img/profileImag2.jpg'
import profileimg3 from '../assets/img/profileImag3.jpg'
import arrow1 from '../assets/img/arrow1.svg'
import arrow2 from '../assets/img/arrow2.svg'
import arrow3 from '../assets/img/arrow3.svg'
import bgImg2 from '../assets/img/bgImg2.svg'


export default function AboutUs() {
    return (
        <>
            <div className='w-screen'>
                <Navbar2 />

                <div className='min-[1920px]:w-[1920px] w-full mx-auto'>

                    <div className='min-[1920px]:w-[1920px] w-full mx-auto absolute -z-10 top-0 lg:h-[800px] h-[600px] bg-cover' style={{ backgroundImage: `URL('${aboutUs1}')` }}></div>

                    {/* about us */}
                    <div className='min-[1240px]:w-[1240px] w-full md:mx-auto md:container md:px-0 px-5 lg:h-[500px] h-[400px] flex flex-col'>
                        <div className='mt-auto sm:mb-0 mb-10 sm:w-[500px] w-full'>
                            <p className='sm:text-[60px] text-[40px] font-bold'>About us</p>
                            <p className='sm:text-2xl'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac.</p>
                        </div>
                    </div>

                    {/* Working with different partners */}
                    <div className='min-[1240px]:w-[1240px] w-full md:mx-auto md:container md:px-0 px-5 lg:grid grid-cols-3'>

                        <div className='col-span-1 flex items-center justify-center'>

                            <div className='lg:mt-24 lg:my-0 my-10 p-2 bg-[#F2F2F3] w-[382px] rounded-3xl z-10'>
                                <div className='bg-brown h-full rounded-3xl'>

                                    <div className='px-20 w-full flex justify-center'>
                                        <div className='py-4 px-5 w-full bg-[#F2F2F3] rounded-b-xl'>
                                            <hr className='border-4 rounded-full border-[#DE753A]' />
                                        </div>
                                    </div>
                                    <div className='py-4'>
                                        <p className='text-[23px] font-bold text-white text-center'>Make Your Team</p>
                                    </div>
                                    <div className='p-5 bg-white flex flex-col rounded-t-3xl rounded-b-3xl'>
                                        <div className='flex items-center justify-between'>
                                            <div className='h-[100px] w-[100px] bg-[#C0E0E3] rounded-2xl'></div>
                                            <div>
                                                <p className='font-medium'>Coach Kevin, ASCM</p>
                                                <p className='font-bold'>FITNESS</p>
                                            </div>
                                            <img src={arrow3} alt="" />
                                        </div>
                                        <hr className='border-2 my-3' />
                                        <div className='flex items-center justify-between'>
                                            <div className='h-[100px] w-[100px] bg-[#EDCABA] rounded-2xl'></div>
                                            <div>
                                                <p className='font-medium'>Coach Amy, PhD</p>
                                                <p className='font-bold'>PSYCHOLOGY</p>
                                            </div>
                                            <img src={arrow1} alt="" />
                                        </div>
                                        <hr className='border-2 my-3' />
                                        <div className='flex items-center justify-between'>
                                            <div className='h-[100px] w-[100px] bg-[#D2E7BB] rounded-2xl'></div>
                                            <div>
                                                <p className='font-medium'>Coach Joe, RDN</p>
                                                <p className='font-bold'>NUTRITION</p>
                                            </div>
                                            <img src={arrow2} alt="" />
                                        </div>
                                        <hr className='border-2 my-3' />
                                        <div className='flex items-center justify-between'>
                                            <div className='h-[100px] w-[100px] bg-[#EFDAC2] rounded-2xl'></div>
                                            <div>
                                                <p className='font-medium'>Coach Kelly, RD</p>
                                                <p className='font-bold'>DIET</p>
                                            </div>
                                            <img src={arrow1} alt="" />
                                        </div>
                                        <button className='mt-5 py-2 w-full bg-brown rounded-full font-bold text-white '>START NOW</button>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div className='col-span-2 flex md:px-16 px-5'>
                            <div className='mt-auto mb-20'>
                                <p className='title'>Working with different partners</p>
                                <p className='sm:text-2xl'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac. Augue risus est tortor, amet netus faucibus blandit feugiat in.</p>
                            </div>
                        </div>

                    </div>

                    {/* Liza Ferdinant | SMM */}
                    <div className='md:my-40 my-20 w-full bg-[#E5E5E5] md:h-[480px] md:py-0 py-10'>
                        <div className='min-[1240px]:w-[1240px] w-full md:mx-auto md:container md:px-0 px-5'>
                            <div className='grid md:grid-cols-7 w-full'>
                                <div className='col-span-4'>
                                    <img src={aboutUs2} alt="" className='xl:relative bottom-9' />
                                </div>
                                <div className='md:mt-0 mt-5 sm:px-10 px-5 col-span-3 flex flex-col justify-center'>
                                    <p className='text-4xl font-extrabold'>Liza Ferdinant | SMM</p>
                                    <p className='text-2xl mt-5 md:mb-20'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac. Augue risus est tortor, amet netus faucibus blandit feugiat in.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Testimonals */}
                    <div className='min-[1240px]:w-[1240px] mx-auto md:container md:px-0 px-5 mt-20 '>

                        {/* title */}
                        <div className='flex justify-center'>
                            <div className='flex flex-col'>
                                <p className='title'>
                                    Testimonals
                                </p>
                                <div className='flex items-center'>
                                    <div className='w-10 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                                    <div className='ms-2 w-4 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                                </div>
                            </div>
                        </div>.

                        <img src={bgImg2} alt="background design" className='absolute right-0 h-auto hidden md:block' />
                        <p className='text-center text-2xl mt-7 lg:px-56 sm:px-10'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac.
                        </p>

                        <div className='md:mt-36 mt-20 grid md:grid-cols-3 sm:grid-cols-2 lg:gap-20 gap-10 '>

                            <div className='qwe'>
                                <div className='bg-sky lg:py-24 py-14 lg:px-14 px-5 rounded-3xl'>
                                    <p className=' text-para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac.</p>
                                </div>
                                <div className='mt-5 flex justify-center space-x-5'>
                                    <img src={profileimg1} alt="" className='lg:h-18 h-14 rounded-full' />
                                    <div className=''>
                                        <p className='lg:text-[28px]'>Elizabeth Bell</p>
                                        <p className='text-para'>Patient</p>
                                    </div>
                                </div>
                            </div>
                            <div className='qwe md:relative bottom-24'>
                                <div className='bg-sky lg:py-20 py-14 lg:px-14 px-5 rounded-3xl'>
                                    <p className=' text-para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac.
                                        <br />Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac.</p>
                                </div>
                                <div className='mt-5 flex justify-center space-x-5'>
                                    <img src={profileimg2} alt="" className='lg:h-18 h-14 rounded-full' />
                                    <div className=''>
                                        <p className='lg:text-[28px]'>Steve Pen</p>
                                        <p className='text-para'>Practic</p>
                                    </div>
                                </div>
                            </div>
                            <div className='qwe'>
                                <div className='bg-sky lg:py-10 py-5 sm:px-10 px-5 rounded-3xl'>
                                    <p className=' text-para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac.
                                        <br />Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac.
                                        Lore jofayt noi fringilla vel, iaculis in ac ac.</p>
                                </div>
                                <div className='mt-5 flex justify-center space-x-5'>
                                    <img src={profileimg3} alt="" className='lg:h-18 h-14 rounded-full' />
                                    <div className=''>
                                        <p className='lg:text-[28px]'>Justin Lee</p>
                                        <p className='text-para'>Payer</p>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                    {/* career */}
                    <div className='my-20 min-[1240px]:w-[1240px] mx-auto md:container md:px-0 px-5'>
                        <div className='md:grid grid-cols-7'>
                            <div className='col-span-3 space-y-5'>
                                <p className='md:text-[60px] text-[30px] font-bold'>Career</p>
                                <p className='md:text-2xl'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac. Augue risus est tortor, amet netus faucibus blandit feugiat in.</p>
                                <button className='bg-brown py-3 px-10 md:text-2xl text-white font-bold rounded-xl'>join out team</button>
                            </div>
                            <div className='md:mt-0 mt-5 md:px-5 col-span-4 space-y-3'>
                                <div className='bg-[#F2F2F2] flex justify-between md:px-10 px-5 md:py-4 py-2 rounded-2xl'>
                                    <p className='md:text-2xl'>Looking for a Sales Manager</p>
                                    <span className='text-brown text-3xl'>&#x2192;</span>
                                </div>
                                <div className='bg-[#F2F2F2] flex justify-between md:px-10 px-5 md:py-4 py-2 rounded-2xl'>
                                    <p className='md:text-2xl'>Looking for a Product Manager</p>
                                    <span className='text-brown text-3xl'>&#x2192;</span>
                                </div>
                                <div className='bg-[#F2F2F2] flex justify-between md:px-10 px-5 md:py-4 py-2 rounded-2xl'>
                                    <p className='md:text-2xl'>Looking for a Web Designer</p>
                                    <span className='text-brown text-3xl'>&#x2192;</span>
                                </div>
                                <div className='bg-[#F2F2F2] flex justify-between md:px-10 px-5 md:py-4 py-2 rounded-2xl'>
                                    <p className='md:text-2xl'>Looking for a SMM</p>
                                    <span className='text-brown text-3xl'>&#x2192;</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Partner with Ora */}
                    <div className='my-20 py-10 min-[1240px]:w-[1240px] mx-auto md:container md:px-0 px-5 bg-lightBrown rounded-2xl'>
                        <div className='w-full md:grid grid-cols-8'>
                            <div className='col-span-4 flex justify-center'>
                                <img src={aboutUs3} alt="" />
                            </div>
                            <div className='md:mt-0 mt-5 col-span-4 space-y-5 md:px-10 flex flex-col justify-center'>
                                {/* title */}
                                <div className='flex justify-center'>
                                    <div className='flex flex-col'>
                                        <p className='title'>
                                        Partner with Ora
                                        </p>
                                        <div className='flex items-center'>
                                            <div className='w-10 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                                            <div className='ms-2 w-4 h-0 sm:border-b-4 border-b-2 border-[#DE753A] rounded-full'></div>
                                        </div>
                                    </div>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, enim a ac fringilla vel, iaculis in ac ac.</p>
                                <button className='w-1/2 bg-brown py-3 px-10 md:text-2xl text-white font-bold rounded-xl'>Submit</button>
                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
            </div>
        </>
    )
}
