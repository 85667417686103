import React from 'react'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Home from "../component/Home"
import AboutUs from '../component/AboutUs'
import News from '../component/News'
import ContactUs from '../component/ContactUs'
import Policy from '../component/Policy'
import TermsConditions from '../component/TermsConditions'


export default function Routing() {

    const routingArray = createBrowserRouter([
        {
            path: '/',
            element: <Home />
        },
        {
            path: '/AboutUs',
            element: <AboutUs />
        },
        {
            path: '/News',
            element: <News />
        },
        {
            path: '/ContactUs',
            element: <ContactUs />
        },
        {
            path: '/PrivacyPolicy',
            element: <Policy />
        },
        {
            path: '/TermsConditions',
            element: <TermsConditions />
        }
    ])

    return (
        <>
            <RouterProvider router={routingArray}/>
        </>
    )
}
