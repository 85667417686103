import Routing from "./router/Routing";

function App() {
  return (
    <>
      <Routing/>
    </>
  );
}

export default App;